<template>
  <div v-if="slide">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('slide.update')"
      :title="$t('slide.update')"
      @submitPressed="submitPressed"
    />
    <slide-form
      :slide-obj="slide"
      :action="action"
      @clearAction="action = null"
    />

    <b-card>
      <custom-fields ownerResource="BANNER_SLIDE" :ownerId="slide.id" :existingCustomFields="slide.customFields" />
    </b-card>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import SlideForm from "@/views/Admin/Banners/components/SlideForm.vue";
import CustomFields from "@/components/CustomFields/customFields.vue";

export default {
  components: {
    TitleBar,
    SlideForm,
    CustomFields
  },
  data() {
    return {
      action: null,
      slide: null
    };
  },
  created() {
    this.loadSlide();
  },
  methods: {
    loadSlide() {
      const id = this.$route.params.id;
      this.$BannerSlides.getResource({ id }).then((response) => {
        if (response.status === 200) {
          this.slide = response.data;
        }
      })
    },
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
